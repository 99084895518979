@use "../../styles/colors";

.drawer-container .MuiDrawer-paper {
  position: relative;
  white-space: nowrap;
  background-color: colors.$black;
  color: colors.$white;
  box-sizing: border-box;

  .drawer-chevron-container {
    position: absolute;
    top: 10px;
    right: 5px;
    &.right {
      right: auto;
      left: 5px;
      rotate: 180deg;
    }
    .drawer-chevron {
      color: colors.$white;
      font-size: 40px;
      width: 40px;
      height: 40px;
    }
  }
}

@media (max-width: 769px) {
  .drawer-container .MuiDrawer-paper {
    position: fixed;
  }
}
