@use "../../../../styles/colors";

.form-date-button {
  width: 45px;
  height: clamp(35px, 10vw, 45px);
  min-width: 0px;

  &.unselected {
    color: colors.$yellow;
    border: 1px solid colors.$yellow;
    background-color: colors.$white;
  }
}

.form-date-picker-container {
  display: flex;
  max-width: 300px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  gap: 5px;
  flex-wrap: wrap;
  .form-date-picker {
    width: 120px;
    height: 35px;
    border-radius: 6px;
    background-color: colors.$white;
    color: colors.$black;
    font-family: "Alegreya Sans";
    line-height: 16px;
    outline: none;
    & > * {
      font-size: 13px;
      font-weight: 600;
      height: 35px;
    }
  }
}
