.pdfContainer {
  flex-grow: 1;
  text-align: center;
  width: 100%;
}

.dropZonePdf {
  width: 100%;
  height: 100%;
  border: 2px solid;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 28px 66px -49px rgba(0, 0, 0, 0.69);
}

.uploadedPdf {
  max-width: 100%;
  max-height: 100%;
}
.cloudIcon {
  padding-left: 10px;
  margin-bottom: 10px;
}

.dropZoneErrorPdf {
  border: 2px dashed #d32f2f;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 4px 28px 66px -49px rgba(0, 0, 0, 0.69);
}

.css-uwwqev {
  height: 60%;
}
