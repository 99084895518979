@use "../../../styles/colors";

.header-container {
  box-shadow: none;
  background-color: colors.$bg-gray;

  .header-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0;
    align-items: flex-start;

    .header-mobile {
      display: none;
    }

    .header-breadcrumbs > * * {
      color: colors.$black;
      font-size: 19px;
      font-family: "Alegreya Sans";
      font-weight: 700;
      line-height: 25px;
      text-decoration: none;
    }

    .header-user-container {
      width: 300px;
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 0;

      .header-user-notification {
        color: colors.$yellow;
      }
      .header-user-button {
        display: flex;
        gap: 10px;
        justify-content: flex-start;

        .header-user-info {
          font-family: "Alegreya Sans";
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .MuiToolbar-root {
    height: 53px;
  }
  .header-container {
    height: 53px;
    background-color: colors.$black;

    .header-toolbar {
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      .header-mobile {
        display: flex;
        gap: 10px;
        padding: 0;
        margin: 0;
        .header-mobile-menu svg {
          color: colors.$white;
        }
        .header-logo {
          margin: 0;
          width: 45px;
          height: 45px;
          border-radius: 8px;
          background-image: url("../../../../public/images/logos/Logo.png");
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .header-breadcrumbs {
        display: none;
      }

      .header-user-container {
        width: 60px;

        .header-user-notification {
          display: none;
        }

        .header-user-button {
          .header-user-info {
            display: none;
          }
        }
      }
    }
  }
}
