.compact-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    margin-bottom: 8px;
  
    &.genre-men {
      background-color: #e3f2fd;
    }
  
    &.genre-women {
      background-color: #fce4ec;
    }
  }
  
