@use "../../styles/colors";

.eventcard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 160px;
  border-radius: 20px;
  margin: 0;
  overflow: hidden;
  
 .eventcard-detailed-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
 }
 .eventcard-stock-container{
  max-height: 100px;
 }
  .eventcard-image-container {
    position: relative;
    width: clamp(30%, 300px, 100%);
    height: 100%;
    .eventcard-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .eventcard-details-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 0;

    .eventcard-name-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      font-size: 20px;
      color: colors.$black;
      font-family: "Alegreya Sans";

      .eventcard-name {
        font-size: 20px;
        font-weight: 700;
      }

      .eventcard-status-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 120px;
        color: colors.$black;
        text-transform: uppercase;
        font-family: "Alegreya Sans";

        .eventcard-status-icon {
          color: colors.$yellow;
          margin-right: auto;
        }
      }
    }
    .eventcard-info-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      font-size: 10px;
      color: colors.$black;
      font-family: "Alegreya Sans";
      .eventcard-info {
        font-size: 15px;
        font-family: "Alegreya Sans";
      }
      .eventcard-inscriptions-container {
        display: flex;
        flex-direction: column;
        width: min(100%, 300px);
        .eventcard-inscriptions {
          width: 100%;
          display: flex;
          justify-content: space-between;
          color: colors.$black;
          font-size: 15px;
          font-family: "Alegreya Sans";
        }
        .eventcard-progress {
          height: 15px;
          border-radius: 10px;
          background-color: colors.$white;
          border: 1px solid colors.$yellow;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
          & > * {
            background-color: colors.$yellow;
            border-radius: 50px;
          }
        }
      }
    }
  }
  .eventcard-buttons-container {
    width: min(100%, 500px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    gap: clamp(10px, 15px, 20px);
    .eventcard-buttons {
      font-weight: 600;
    }
    .cancel {
      background-color: colors.$white;
      color: colors.$red;
    }
  }
}

@media (max-width: 768px) {
  .eventcard-container {
    flex-direction: column;
    height: 100%;
    gap: 15px;
    .eventcard-image-container {
      width: 100%;
    }
    .eventcard-details-container {
      width: 100%;
    }
    .eventcard-buttons-container {
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
  }
}
