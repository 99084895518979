@use "../../styles/colors";

.dump-dialog-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0;
  .dump-dialog-title-text {
    color: colors.$yellow;
    font-size: 24px;
    font-weight: 600;
  }
  .dump-dialog-title-icon {
    font-size: 30px;
  }
}

.dump-dialog-container {
  justify-content: space-between;

  .dump-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding-inline: 20px;
    .user-dialog-content-text {
      width: 100%;
      text-align: left;
      color: colors.$red;
      text-wrap: pretty;
    }
  }

  .dump-download-button {
    color: colors.$white;
    text-decoration: none;
    width: 100%;
    padding: 10px 0;
    border-radius: 10px;
    background-color: colors.$yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    &:hover {
      background-color: colors.$yellow;
    }
  }
}
