@use "../../../../styles/colors";

.form-social-media-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colors.$red;
  border-radius: 5px;
  padding: 2px;
  .form-social-media-icon {
    color: colors.$white;
  }
}

.form-text-field {
  width: 100%;
  & > * {
    height: 45px;
    & > * svg {
      color: colors.$yellow;
    }
  }
  &.updated {
    border: 1px solid colors.$yellow;
    border-radius: 6px;
  }
}

.form-section-title {
  color: colors.$black;
  font-size: 19px;
  font-family: "Alegreya Sans";
  font-weight: 700;
  line-height: 25px;
}
