@use "../../../styles/colors";

.add-user-form-title {
  color: colors.$black;
  font-size: 19px;
  font-family: "Alegreya Sans";
  font-weight: 700;
  line-height: 25px;
}

.representative-title {
  margin-bottom: 10px;
}

.sports-titles {
  margin-top: 20px;
  margin-bottom: 10px;
}

.form-button-add-representative {
  color: colors.$yellow;
}

.form-paper-button {
  border-radius: 10px;
}

.check-radio-red {
  color: colors.$red;
  &.Mui-checked {
    color: colors.$red;
  }
}

.check-radio-orange {
  color: colors.$yellow;
  &.Mui-checked {
    color: colors.$yellow;
  }
}
