@use "./_colors.scss";
@use "./fonts";

html body {
  background-color: colors.$bg-gray;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Alegreya Sans", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-text .MuiInputBase-root {
  min-height: 30px;
  //border: 1px solid #232323;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: colors.$white;
  color: colors.$black;
  font-size: 16px;
  font-family: "Alegreya Sans";
  line-height: 21px;
  outline: none;
}
.input-select {
  min-height: 30px;
  //border: 1px solid #232323;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: colors.$white;
  color: colors.$black;
  font-size: 16px;
  font-family: "Alegreya Sans";
  line-height: 21px;
  outline: none;
}
