@import "../../styles/colors";

.home-button {
  background-color: $yellow;
  color: $white;
  font-size: 20px;
  text-transform: none;
  border-radius: 8px;
  padding: 12px 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.home-button:hover {
  background-color: $dark-yellow;
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.home-button:active {
  background-color: darken($dark-yellow, 5%);
  transform: scale(0.98);
}

.event-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 40px;
  border: 1px solid #e0e0e0;
}

.event-row {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0;
}

.event-image {
  width: 100%;
  height: auto;
  max-height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.event-link {
  color: $yellow;
  border-color: $yellow;

  &:hover {
    background-color: $dark-yellow;
    color: $white;
    border-color: $dark-yellow;
  }
}
