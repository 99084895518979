@use "../../styles/colors";

.reset-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .reset-paper {
    position: relative;
    width: 600px;
    height: 600px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: colors.$white;
    border-radius: 10px;
    border: 2px solid colors.$white;
    box-sizing: border-box;
    box-shadow: 0 2px 10px colors.$black;

    .form {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .reset-password-input {
        margin-bottom: 1rem;
      }
    }

    .reset-logo {
      width: 140px;
      height: 150px;
      border: none;
      border-radius: 8px;
      background-image: url("../../../public/images/logos/01.png");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .reset-title-container {
      display: flex;
      justify-content: space-between;
      //   grid-template-columns: 1fr auto 1fr;
      align-items: center;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem;

      .reset-back-button {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        border-radius: 0;
        .reset-back-icon {
          color: colors.$red;
          width: 100%;
          height: 100%;
        }
      }

      .reset-title {
        color: colors.$red;
        font-size: 23px;
        font-family: "Alegreya Sans";
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        pointer-events: none;
      }
    }

    .reset-logos-container {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 1rem;
      flex-direction: row;
      .reset-logo-sponsors {
        width: 40%;
      }
    }

    .reset-error-text {
      color: colors.$red;
    }
  }
}

@media (width < 450px) {
  .reset-container .reset-paper .reset-logos-container .reset-logo-sponsors {
    width: 50%;
  }
  .reset-container .reset-paper .form {
    width: 100%;
  }
}
