.inscriptions-container-create {
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  .inscriptions-buttons-container {
    justify-content: flex-end;
  }
}

.create-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 20px;
  text-align: center;
  width: 40%;
}

.create-button {
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 800;
}

.no-wrap {
  white-space: nowrap;
}
