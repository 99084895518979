@use "../../../styles/colors";

.admins-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  color: white;
  font-size: 20px;
  text-align: center;

  .admins-header-searchInput-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 40%;
    color: white;
    font-size: 20px;
    text-align: center;

    .admins-header-searchInput-icon {
      font-size: 30px;
      color: colors.$yellow;
    }
  }

  .admins-header-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 50%;

    .admins-header-actions-button {
      text-decoration: none;
      font-size: clamp(10px, 2vw, 15px);
      text-wrap: nowrap;
    }
  }

  .admins-filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
    padding: 0;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .admins-header {
    flex-wrap: wrap;
    .admins-header-searchInput-container {
      width: 100%;
    }
    .admins-header-actions-container {
      width: 100%;
      flex-wrap: wrap;
    }
  }
}
