@use "../../styles/colors";

.clubs-container {
  margin: 0%;
  padding: 0;
  max-width: none;

  .clubs-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100px;
    color: white;
    font-size: 20px;
    text-align: center;

    .clubs-header-searchInput-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 40%;
      color: white;
      font-size: 20px;
      text-align: center;

      .clubs-header-searchInput-icon {
        font-size: 30px;
        color: colors.$yellow;
      }
    }

    .clubs-header-actions-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 30px;
      align-items: center;
      margin: 0;
      padding: 0;

      .clubs-filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        padding: 0;
        width: 100%;
        min-height: 50px;
        gap: 20px;

        .clubs-header-actions-button {
          text-decoration: none;
          font-size: clamp(10px, 2vw, 15px);
          text-wrap: nowrap;
        }
      }
    }
  }

  .clubs-count {
    font-size: 15px;
    font-weight: 600;
  }

  .clubs-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 20px 0;
    list-style-type: none;
    gap: 20px;
  }
}

.pagination-container {
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .clubs-container {
    padding: 20px;

    .clubs-header {
      align-items: flex-start;
      height: auto;
      .clubs-header-searchInput-container {
        width: 50%;
      }
    }
  }
}
