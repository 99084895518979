@use "../../../styles/colors";

.users-dialog-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0;
  .users-dialog-title-text {
    color: colors.$yellow;
    font-size: 24px;
    font-weight: 600;
  }
  .users-dialog-title-icon {
    font-size: 30px;
  }
}

.users-dialog-container {
  justify-content: space-between;

  .users-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding-inline: 20px;
    .user-dialog-content-text {
      width: 100%;
      text-align: left;
      color: colors.$red;
    }
  }
}
