@use "../../styles/colors";

.users-tabs {
  padding: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 20px;
  .users-tab {
    padding: 0 10px;
    &.Mui-selected {
      color: colors.$red;
    }
  }
}
.users-tab-indicator {
  background-color: colors.$red;
}

.users-container {
  margin: 0%;
  padding: 0;
  max-width: none;

  .users-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    color: white;
    font-size: 20px;
    text-align: center;

    .users-header-searchInput-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 40%;
      color: white;
      font-size: 20px;
      text-align: center;

      .users-header-searchInput-icon {
        font-size: 30px;
        color: colors.$yellow;
      }
    }

    .users-header-actions-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 50%;

      .users-header-actions-button {
        text-decoration: none;
        font-size: clamp(10px, 2vw, 15px);
        text-wrap: nowrap;
      }
    }

    .users-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 5px 0;
      padding: 0;
      width: 100%;
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  .users-count {
    font-size: 15px;
    font-weight: 600;
  }

  .users-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 20px 0;
    list-style-type: none;
    gap: 20px;
  }

  .pagination-container {
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .users-container {
    padding: 20px;

    .users-header {
      flex-wrap: wrap;
      .users-header-searchInput-container {
        width: 100%;
      }
      .users-header-actions-container {
        width: 100%;
        flex-wrap: wrap;
      }
    }
  }
}
