@use "../../styles/colors";

.clubcard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 180px;
  padding: 20px;
  border-radius: 20px;
  margin: 0;

  .clubcard-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 20%;
    height: 100%;
    text-align: left;
    text-wrap: pretty;

    .clubcard-name {
      width: 100%;
      word-break: break-all;
      font-size: 20px;
      font-weight: 600;
      color: colors.$black;
      text-transform: uppercase;
      font-family: "Alegreya Sans";
    }

    .clubcard-info-details-container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: auto 0;

      .clubcard-info-details {
        font-size: 15px;
        font-family: "Alegreya Sans";
      }
    }
  }

  .clubcard-details-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
    margin: 0;
    padding: 0;

    .clubcard-detail-container {
      display: flex;
      flex-direction: column;
      width: 20%;
      height: 100%;
      gap: 5px;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .clubcard-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: clamp(25px, 10vw, 60px);
        height: clamp(25px, 10vw, 60px);
        background-color: colors.$yellow;
        border-radius: 50%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

        .clubcard-icon {
          color: colors.$white;
          font-size: clamp(25px, 5vw, 40px);
        }
      }
      .clubcard-data-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .clubcard-data {
          font-size: clamp(11px, 3vw, 15px);
          color: colors.$black;
          text-align: center;
          font-weight: 800;
          font-family: "Alegreya Sans";

          &.value {
            font-size: clamp(15px, 4vw, 20px);
            font-weight: 800;
          }
        }
      }
    }
  }

  .clubcard-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    height: 100%;
    gap: 20px;
    font-family: "Alegreya Sans";

    .clubcard-buttons {
      z-index: 10;
    }
  }
}

@media (max-width: 768px) {
  .clubcard-container {
    flex-direction: column;
    height: 100%;
    gap: 40px;
    .clubcard-info-container {
      width: 100%;
    }
    .clubcard-details-container {
      justify-content: space-around;
      width: 100%;
    }
    .clubcard-buttons-container {
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
  }
}
