@use "../../../../styles/colors";
.drag-drop-container {
  justify-content: center;
  gap: 20px;

  .drag-drop-text {
    text-align: center;
  }

  .drag-drop-icon {
    font-size: 3rem;
    color: colors.$yellow;
  }
}

.zone {
  align-items: center;
  border-width: 2px;
  border-style: dashed;
  border-color: #000;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;

  &:hover {
    border-color: colors.$yellow;
    cursor: pointer;
  }
}

.progressBar {
  bottom: 14px;
  position: absolute;
  width: 100%;
  padding-inline: 10px;
}

.file {
  background: linear-gradient(to bottom, #eee, #ddd);
  border-radius: 20px;
  display: flex;
  height: 90%;
  position: relative;
  z-index: 10;
  flex-direction: column;
  justify-content: center;
}

.info {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.name {
  color: #000;
  border-radius: 3px;
  font-size: 15px;
  margin-bottom: 0.5em;
}

.default {
  border-color: #ccc;
}

.remove {
  height: 23px;
  position: absolute;
  right: 6px;
  top: 6px;
  width: 23px;
  z-index: 100;
}
