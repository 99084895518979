@use "../../styles/colors";

.icon-button-accept {
  color: colors.$yellow;
  font-size: 2rem;
  cursor: pointer;
}

.icon-button-cancel {
  color: colors.$red;
  font-size: 2rem;
}

.checkbox-accept {
  color: colors.$yellow;
  cursor: pointer;
  &:checked {
    color: colors.$yellow;
  }
  &.Mui-disabled {
    color: colors.$yellow;
  }
}
