@use "../../../styles/colors";

.container-admin-list {
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 50px;

  .admin-table-head {
    border-bottom: 2px solid colors.$red;
  }

  .admin-icon-button-accept {
    color: colors.$yellow;
    font-size: 2rem;
  }

  .admin-icon-button-cancel {
    color: colors.$red;
    font-size: 2rem;
  }

  .admin-no-wrap {
    white-space: nowrap;
  }
}
