@use "../../../styles/colors";

.inscriptions-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding-bottom: 20px;
  width: 100%;

  .inscriptions-count {
    font-size: 15px;
    font-weight: 500;
  }
}

.inscriptions-container-pending {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .inscriptions-header-searchInput-container {
    display: flex;
    width: 30%;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 20px;
    text-align: center;

    .inscriptions-header-searchInput-icon {
      font-size: 30px;
      color: colors.$yellow;
      width: fit-content;
    }
  }
}
