@use "../../styles/colors";

.login-container {
  width: 300px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-logo {
    width: 140px;
    height: 150px;
    margin: 1rem;
    border: none;
    border-radius: 8px;
    background-image: url("../../../public/images/logos/01.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-title {
    color: colors.$red;
    font-size: 23px;
    font-family: "Alegreya Sans";
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }

  .login-form {
    width: 100%;

    .login-checkbox-container {
      margin: 5px 0;
      display: flex;
      align-items: center;
      gap: 5px;
      .login-checkbox {
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 16px;
        height: 20px;
        pointer-events: auto;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(3, 3, 3, 0.1);
        border: 1px solid rgba(3, 3, 3, 0.1);
        &.Mui-checked {
          color: colors.$yellow;
          border: 1px solid colors.$yellow;
          background-color: transparent;
        }
      }
      .MuiTypography-root {
        color: #232323;
        font-size: 16px;
        font-family: "Alegreya Sans";
        // line-height: 21px;
      }
    }
  }

  .login-error-text {
    color: colors.$red;
    width: 100%;
    font-size: 16px;
    font-family: "Alegreya Sans";
    line-height: 21px;
    text-align: center;
  }

  .login-button-login {
    color: colors.$white;
    font-size: 23px;
    font-family: "Alegreya Sans";
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    outline: none;
  }

  .login-button-create {
    color: colors.$white;
    font-size: 16px;
    font-family: "Alegreya Sans";
    font-weight: 500;
    line-height: 21px;
    text-transform: uppercase;
    outline: none;
  }

  .login-forget-text {
    width: 100%;
    color: #3c3c3c;
    font-size: 16px;
    font-family: "Alegreya Sans";
    line-height: 21px;
    text-align: right;
  }

  .login-logos-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
    flex-direction: row;
    .login-logo-sponsors {
      height: 100%;
      width: 50%;
      margin-top: 100px;
    }
  }
}
