.teams-check-container {
  display: flex;
  align-items: center;
}

.categories-inscriptions-container {
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 90%;
  .categories-inscriptions-textfield {
    max-width: 30%;
  }
}

@media (width < 930px) {
  .categories-inscriptions-container {
    .categories-inscriptions-textfield {
      max-width: 40%;
    }
  }
}
