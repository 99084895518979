@use "../../../styles/colors";

.nav-drawer-logo {
  margin: 30px auto;
  min-width: 148px;
  height: 154px;
  border-radius: 8px;
  background-image: url("../../../../public/images/logos/Logo.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-drawer-list-item {
  height: 46px;

  &.item-selected,
  &.item-selected:hover {
    background-color: colors.$yellow;
  }
  .nav-drawer-item-text {
    font-size: 19px;
    font-family: "Alegreya Sans";
    line-height: 32px;
    text-transform: capitalize;
  }
}
