@use "../../../styles/colors";

.user-drawer-container {
  padding: 25px;

  .user-drawer-avatar {
    width: 154px;
    height: 154px;
    margin: 0 auto;
  }

  .user-drawer-list {
    color: colors.$white;
    font-size: 19px;
    font-family: "Alegreya Sans";
    line-height: 32px;

    .user-drawer-mail {
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 15px;
      text-transform: lowercase;
    }
  }
}
