@use "../../styles/colors";

.events-container {
  margin: 0%;
  padding: 0;
  max-width: none;

  .events-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 80px;
    color: white;
    font-size: 20px;
    text-align: center;

    .events-header-searchInput-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 40%;
      color: white;
      font-size: 20px;
      text-align: center;

      .events-header-searchInput-icon {
        font-size: 30px;
        color: colors.$yellow;
      }
    }

    .events-header-actions-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 30px;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 40%;

      .events-header-actions-button {
        text-decoration: none;
        font-size: clamp(10px, 2vw, 15px);
        text-wrap: nowrap;
      }
    }
  }
  .events-filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 0;
    padding: 0;
    width: 100%;
    min-height: 50px;
    gap: 20px;
  }

  .events-count {
    font-size: 15px;
    font-weight: 600;
  }

  .events-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 20px 0;
    list-style-type: none;
    gap: 20px;
  }

  .events-empty {
    margin-top: 20px;
  }

  .pagination-container {
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .events-container {
    padding: 20px;

    .events-header {
      align-items: flex-start;
      height: auto;
      .events-header-searchInput-container {
        width: 50%;
      }
      .events-header-actions-container {
        width: 40%;
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }
  }
}
