@use "../../../styles/colors";

.stepper-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10%;
  margin: 10px 0 20px;

  .stepper-step-container {
    cursor: pointer;

    & > * * {
      color: colors.$red;
      font-family: "Alegreya Sans";
      font-weight: 600;
      font-size: 15px;
    }

    .stepper-step {
      opacity: 0.4;
      color: colors.$red;
      &.active {
        opacity: 1;
      }
      .stepper-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        // padding: 50px;
        cursor: pointer;
        color: colors.$red;
        //   background-color: colors.$white;
        // color: colors.$white;
      }
    }
  }
}

.error-message {
  color: colors.$red;
  margin-bottom: 10px;
  text-align: center;
}
