@use "../../styles/colors";

.ranking-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px 15px 90px;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  max-width: 1200px;

  .ranking-table-top {
    display: flex;
    width: 100%;
    min-height: 65px;
    padding: 10px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .ranking-table-top-title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-family: "Alegreya Sans";
      justify-content: flex-start;
      padding: 15px 0;
      .ranking-title {
        font-size: clamp(15px, 20vw, 14px);
        font-weight: 700;
      }
      .ranking-date {
        font-size: clamp(14px, 20vw, 12px);
      }
    }

    .ranking-table-top-filters {
      width: 80%;
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
      .ranking-select-field {
        height: 35px;
        max-width: 30%;
      }
      .ranking-date-picker {
        padding: 0;
        background-color: colors.$white;
        height: 35px;
        min-width: 200px;
        max-width: 33%;
        & > * {
          height: 35px;
        }
      }
    }
  }

  .ranking-header {
    display: flex;
    width: 100%;
    align-items: center;

    .ranking-search {
      width: 50%;
    }

    .ranking-filter-icon {
      display: none;
      color: colors.$yellow;
    }
  }

  .ranking-body {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: colors.$gray;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 15px;

    .ranking-card-table-container {
      width: 100%;
      height: 100%;
      background-color: colors.$white;
      border-radius: 15px;
      .ranking-card-table-row {
        white-space: nowrap;
        .user-icon-button-accept {
          color: colors.$yellow;
          font-size: 2rem;
        }
      }
    }
  }
}

@media (width < 870px) {
  .ranking-container {
    .ranking-table-top {
      .ranking-table-top-filters {
        width: 100%;
        .ranking-select-field {
          max-width: 45%;
        }
        .ranking-date-picker {
          max-width: 45%;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .ranking-container {
    .ranking-header {
      .ranking-search {
        width: 100%;
      }
      .ranking-filter-icon {
        display: flex;
      }
    }
  }
}

@media (max-width: 412px) {
  .ranking-container {
    .ranking-table-top {
      .ranking-table-top-filters {
        .ranking-select-field {
          max-width: 100%;
        }
        .ranking-date-picker {
          max-width: 100%;
        }
      }
    }
  }
}
