@use "../../styles/colors";

.container-date-range {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  .date-picker {
    min-width: 211px;
    background-color: colors.$white;
  }
}
