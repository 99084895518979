@use "../../../styles/colors";

.sport-card-container {
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
  margin-bottom: 20px;

  .sport-card-container-first {
    align-items: center;
    .sport-card-container-avatar {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      aspect-ratio: 1/1;
    }
  }

  .sport-card-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .sport-card-list-text {
    font-weight: bold;
  }

  .sport-card-divider {
    background-color: colors.$bg-gray;
    height: 5px;
    margin: 20px 0;
    border-radius: 20px;
    width: 100%;
    border-bottom-width: 0;
  }
}
