.validated-filters-container {
  margin-bottom: 10px;
}

.validated-search-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 20px;
  text-align: center;
}
