@use "../../styles/colors";

.federations-container {
  margin: 0%;
  padding: 0;
  max-width: none;

  .federations-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100px;
    color: white;
    font-size: 20px;
    text-align: center;

    .federations-header-searchInput-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 40%;
      color: white;
      font-size: 20px;
      text-align: center;

      .federations-header-searchInput-icon {
        font-size: 30px;
        color: colors.$yellow;
      }
    }

    .federation-header-actions-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 50%;

      .federation-header-actions-button {
        text-decoration: none;
        font-size: clamp(10px, 1vw, 15px);
        text-wrap: nowrap;
      }
    }
  }

  .federations-count {
    font-size: 15px;
    font-weight: 600;
  }

  .federations-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 20px 0 30px 0;
    list-style-type: none;
    gap: 20px;
  }
}

.pagination-container {
  justify-content: space-between;
  align-items: center;
}

.federations-empty {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .federations-container {
    padding: 20px;

    .federations-header {
      align-items: flex-start;
      height: auto;
      flex-wrap: wrap;
      gap: 10px;
      .federations-header-searchInput-container {
        width: 100%;
      }
      .federation-header-actions-container {
        width: 100%;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
