@use "../../styles/colors";

.inscriptions-tabs {
  padding: 0;
  margin: 0;
  width: 100%;

  .inscriptions-tab {
    padding: 0 15px;
    &.Mui-selected {
      color: colors.$red;
    }
  }

  .users-badge-count {
    padding-right: 12px;
    .MuiBadge-colorPrimary {
      background-color: colors.$yellow;
    }
  }
}

.inscriptions-tab-indicator {
  background-color: colors.$red;
}

@media (width < 600px) {
  .inscriptions-header-searchInput-container {
    width: 80%;
  }
}
