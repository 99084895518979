@use "../../../styles/colors";

.add-federation-container {
  padding: 20px;
  .add-federation-form-title {
    color: colors.$black;
    font-size: 19px;
    font-family: "Alegreya Sans";
    font-weight: 700;
    line-height: 25px;
  }
  .add-federation-section-container {
    width: 100%;
    padding: 0 0 20px;
  }

  .add-federation-input-label {
    color: colors.$black;
    font-size: 13px;
    font-family: "Alegreya Sans";
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 3px;
  }
  .add-federation-text-field {
    width: 100%;

    & > * {
      height: 45px;

      & > * svg {
        color: colors.$yellow;
      }
    }
  }
  .add-federation-select-field {
    width: 100%;
    height: 45px;
  }

  .add-federation-checkbox-container {
    margin: 0;
    .add-federation-checkbox {
      color: colors.$red;
    }
  }

  .add-federation-button {
    font-size: 20px;
    font-weight: 800;
    height: 40px;
    &.cancel {
      background-color: colors.$white;
      color: colors.$red;
    }
  }

  .form-input-error {
    color: colors.$red;
    font-size: 11px;
    font-family: "Alegreya Sans";
    font-weight: 400;
    line-height: 13px;
  }
}

.form-button {
  font-size: 20px;
  font-weight: 800;
  height: 40px;
  &.cancel {
    background-color: colors.$white;
    color: colors.$red;
  }

  &.small-text {
    font-size: 14px;
  }

  &.small-size {
    width: 35px;
    height: 35px;
    min-width: 0px;
  }
}
