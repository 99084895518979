@use "../../styles/colors";

.form-container {
  padding: 20px;
  .form-paper {
    border-radius: 14px;
  }
  .form-section-title {
    color: colors.$black;
    font-size: 19px;
    font-family: "Alegreya Sans";
    font-weight: 700;
    line-height: 25px;
  }
  .form-section-container {
    width: 100%;
    padding: 0 0 20px;
  }

  .form-card-container {
    width: 100%;
    padding: 15px;
    border-radius: 15px;

    .form-card-label {
      color: colors.$red;
      font-size: 15px;
      font-family: "Alegreya Sans";
      font-weight: 400;
      line-height: 13px;
      margin-bottom: 3px;

      &.small-size {
        width: 14px;
        height: 14px;
      }
    }
  }

  .form-input-label {
    color: colors.$black;
    font-size: 13px;
    font-family: "Alegreya Sans";
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 3px;
  }
  .form-input-error {
    color: colors.$red;
    font-size: 11px;
    font-family: "Alegreya Sans";
    font-weight: 400;
    line-height: 13px;
    // margin-bottom: 3px;
  }
  .form-text-field {
    width: 100%;

    & > * {
      height: 45px;
      //border: 1px solid colors.$black;

      & > * svg {
        color: colors.$yellow;
      }
    }
    &.updated {
      border: 1px solid colors.$yellow;
      border-radius: 6px;
    }
  }
  .form-select-field {
    width: 100%;
    height: 45px;

    // & > * {
    //   border: 1px solid colors.$black;

    //   & > * svg {
    //     color: colors.$yellow;
    //   }
    // }
  }

  .form-checkbox-container {
    margin: 0;
    .form-checkbox {
      color: colors.$red;
    }
  }

  .form-button {
    font-size: 20px;
    font-weight: 800;
    height: 40px;
    &.cancel {
      background-color: colors.$white;
      color: colors.$red;
    }

    &.small-text {
      font-size: 14px;
    }

    &.small-size {
      width: 35px;
      height: 35px;
      min-width: 0px;
    }
  }

  .form-date-button {
    width: 45px;
    height: clamp(35px, 10vw, 45px);
    min-width: 0px;

    &.unselected {
      color: colors.$yellow;
      border: 1px solid colors.$yellow;
      background-color: colors.$white;
    }
  }

  .form-date-picker-container {
    display: flex;
    max-width: 300px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
    gap: 5px;
    flex-wrap: wrap;
    .form-date-picker {
      width: 120px;
      height: 35px;
      border-radius: 6px;
      background-color: colors.$white;
      color: colors.$black;
      font-family: "Alegreya Sans";
      line-height: 16px;
      outline: none;
      & > * {
        font-size: 13px;
        font-weight: 600;
        height: 35px;
        //border: 1px solid colors.$black;
      }
    }
  }

  .form-social-media-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$red;
    border-radius: 5px;
    padding: 2px;
    .form-social-media-icon {
      color: colors.$white;
    }
  }

  .form-section-remove-button {
    color: colors.$yellow;
  }

  .form-switch {
    width: 50px;
    height: 25px;
    padding: 0;
    display: flex;
    &:active {
      & .MuiSwitch-thumb {
        width: 27px;
      }
      & .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(21px);
      }
    }
    & .MuiSwitch-switchBase {
      padding: 2px;
      &.Mui-checked {
        transform: translateX(24px);
        color: #fff;
        & + .MuiSwitch-track {
          opacity: 1;
          background-color: colors.$yellow;
        }
      }
    }
    & .MuiSwitch-thumb {
      box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
      width: 21px;
      height: 21px;
      border-radius: 18px;
      transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    & .MuiSwitch-track {
      border-radius: 16px;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
    }
  }
}
