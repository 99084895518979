@use "../../../styles/colors";

.category-dialog-title {
  font-weight: bolder;
  color: colors.$red;
  padding-top: 20px;
}

.category-dialog-text {
  color: #000;
  text-align: center;
  font-size: 22px;
  margin-bottom: 10px;
}

.category-dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 20px;
  padding-inline: 20px;
}

.category-button-cancel {
  font-size: 20px;
  font-weight: 800;
  height: 40px;
  background-color: colors.$white;
  color: colors.$red;
  &:hover {
    background-color: colors.$dark-red;
    color: colors.$white;
  }
}

.category-button-accept {
  font-size: 20px;
  font-weight: 800;
  height: 40px;
  color: colors.$white;
  &:hover {
    background-color: colors.$dark-yellow;
  }
}

.actual-category-container {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.new-category-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reserve-text-container {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 10px;
  color: colors.$red;

  .reserve-text {
    display: flex;
    text-align: center;
    text-wrap: balance;
  }
}
