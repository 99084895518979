@use "../../styles/colors";

.container-user-list {
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 50px;

  .user-table-head {
    border-bottom: 2px solid colors.$red;
  }

  .user-icon-button-accept {
    color: colors.$yellow;
    font-size: 2rem;
  }

  .user-icon-button-cancel {
    color: colors.$red;
    font-size: 2rem;
  }

  .user-no-wrap {
    white-space: nowrap;
  }
}
