@use "../../../../styles/colors";

.container-inscription-list {
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 50px;

  .table-head {
    border-bottom: 2px solid colors.$red;
  }

  .icon-button-accept {
    color: colors.$yellow;
    font-size: 2rem;
  }

  .icon-button-cancel {
    color: colors.$red;
    font-size: 2rem;
  }

  .download-button {
    background-color: colors.$yellow;
    border-radius: 10px;

    &:hover {
      background-color: colors.$dark-yellow;
    }
  }

  .checkbox-inscriptions {
    &.Mui-checked {
      color: colors.$yellow;
    }
  }

  .no-wrap {
    white-space: nowrap;
  }
}
