@use "../../styles/colors";

.custom-select-container {
  .custom-select-label {
    color: colors.$black;
    font-size: 14px;
    font-family: "Alegreya Sans";
    line-height: 18px;
  }

  .custom-select-textfield {
    height: 36px;
    //border: 1px solid #232323;
    box-sizing: border-box;
    background-color: colors.$white;
    color: colors.$black;
    font-size: 16px;
    font-family: "Alegreya Sans";
    line-height: 21px;
    outline: none;

    .Mui-focused {
      border-color: colors.$yellow;
    }
  }
}
