@use "../../../styles/colors";

.btn-check-validated {
  background-color: colors.$yellow;
  color: colors.$white;
}
.btn-check {
  border: 2px solid colors.$yellow;
  color: colors.$yellow;
}
